<template>
  <div>
    <!-- Versión escritorio -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">

        </v-col>
      </v-row>
      <!-- Formulario común con datos y rutas específicas -->
      <commond-form :request="data" :route="routesContactData.routeCommond" :run="run"
        :action="routesContactData.routeCommondAction" @cancel="run = false" @success="ChangeEmails"
        @afterError="changeComponent">
        <template v-slot:data>
          <v-row>
            <!-- Campo de texto para el teléfono, visible si data.telefono está definido -->
            <v-col cols="12" m="12" md="6" lg="6" xl="6" v-if="data.telefono">
              <v-text-field label="Teléfono" outlined v-model="data.telefono" primary color="#00a7e4" type="text"
                :rules="requiredRule" placeholder="##########" v-mask="'##########'"></v-text-field>
            </v-col>
            <!-- Campo de texto para el celular, visible si data.celular está definido -->
            <v-col m="12" md="6" lg="6" xl="6" v-if="data.celular">
              <v-text-field label="Celular" outlined v-model="data.celular" primary color="#00a7e4" type="text"
                :rules="requiredRule" placeholder="##########" v-mask="'##########'"></v-text-field>
            </v-col>
          </v-row>
          <!-- Iteración sobre los correos electrónicos para mostrarlos y permitir su edición -->
          <v-row v-for="(correo, idx) in dataCorreos.correos" :key="idx">
            <v-col cols="2" m="2" md="2" lg="1" xl="1" class="whitout-padding-bottom">
              <span>
                <h3>{{ idx + 1 }}°</h3>
              </span>
            </v-col>
            <v-col cols="8" m="5" md="5" lg="4" xl="4" class="whitout-padding-top">
              <!-- Componente de autocompletado para el correo electrónico -->
              <CommonEmailAutocomplete :emailEdit="correo.correo" @emailUpdate="(e) => (correo.correo = e)">
              </CommonEmailAutocomplete>
            </v-col>
            <v-col cols="2" m="5" md="5" lg="2" xl="2" class="whitout-padding-top whitout-padding-bottom">
              <!-- Botón para eliminar el correo, visible si hay más de un correo -->
              <v-btn @click="chekDeleteIdx(idx)" icon v-if="dataCorreos.correos.length > 1">
                <v-icon color="#ABB1B3">mdi-delete</v-icon>
              </v-btn>
              <!-- Botón para marcar el correo como favorito -->
              <v-btn @click="changeFavoriteEmail(idx)" icon>
                <v-icon :color="correo.favorito ? '#00a7e4' : '#ABB1B3'">
                  {{ correo.favorito ? "mdi-heart" : "mdi-heart-outline" }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- Botón para agregar un nuevo correo, visible si hay menos de 5 correos -->
          <v-row class="whitout-padding-top" v-if="dataCorreos.correos.length < 5">
            <v-col cols="12" m="12" md="6" lg="6" xl="6" class="whitout-padding-top">
              <v-btn elevation="0" block @click="AddNewMail">
                <v-icon rigth>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </commond-form>
    </template>
    <!-- Versión móvil -->
    <template v-else>
      <div class="mobile-container">
        <commond-form
          :request="data"
          :route="routesContactData.routeCommond"
          :run="run"
          :action="routesContactData.routeCommondAction"
          @cancel="run = false"
          @success="ChangeEmails"
          @afterError="changeComponent"
        >
          <!-- Contenido del formulario -->
          <template v-slot:data>
            <v-row>
              <v-col cols="12" v-if="data.telefono">
                <v-text-field
                  label="Teléfono"
                  outlined
                  v-model="data.telefono"
                  primary
                  color="#00a7e4"
                  type="text"
                  :rules="requiredRule"
                  placeholder="##########"
                  v-mask="'##########'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="data.celular">
                <v-text-field
                  label="Celular"
                  outlined
                  v-model="data.celular"
                  primary
                  color="#00a7e4"
                  type="text"
                  :rules="requiredRule"
                  placeholder="##########"
                  v-mask="'##########'"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Iteración sobre los correos electrónicos para mostrarlos y permitir su edición -->
          <v-row v-for="(correo, idx) in dataCorreos.correos" :key="idx">
            <v-col cols="2" m="2" md="2" lg="1" xl="1" class="whitout-padding-bottom">
              <span>
                <h3>{{ idx + 1 }}°</h3>
              </span>
            </v-col>
            <v-col cols="8" m="5" md="5" lg="4" xl="4" class="whitout-padding-top">
              <!-- Componente de autocompletado para el correo electrónico -->
              <CommonEmailAutocomplete :emailEdit="correo.correo" @emailUpdate="(e) => (correo.correo = e)">
              </CommonEmailAutocomplete>
            </v-col>
            <v-col cols="2" m="5" md="5" lg="2" xl="2" class="whitout-padding-top whitout-padding-bottom">
              <!-- Botón para eliminar el correo, visible si hay más de un correo -->
              <v-btn @click="chekDeleteIdx(idx)" icon v-if="dataCorreos.correos.length > 1">
                <v-icon color="#ABB1B3">mdi-delete</v-icon>
              </v-btn>
              <!-- Botón para marcar el correo como favorito -->
              <v-btn @click="changeFavoriteEmail(idx)" icon>
                <v-icon :color="correo.favorito ? '#00a7e4' : '#ABB1B3'">
                  {{ correo.favorito ? "mdi-heart" : "mdi-heart-outline" }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
            <!-- Botón para agregar un nuevo correo -->
            <v-row class="mobile-actions" v-if="dataCorreos.correos.length < 5">
              <v-col cols="12">
                <v-btn elevation="0" block @click="AddNewMail">
                  <v-icon rigth>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </commond-form>
        <!-- Botones de acción al final en móvil -->
        <div class="mobile-actions">
          <v-btn
            rounded
            dark
            outlined
            @click="changeComponent"
            class="mobile-action-button-cancel"
          >
            Cancelar
          </v-btn>
          <v-btn rounded dark class="common-botton mobile-action-button" @click="eliminatedEmptyEmails">
            Guardar
            <v-icon rigth>mdi-content-save</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
    <!-- Modal de confirmación común -->
    <CommonConfirmationModal :dialog="dialog" @userChoice="deleteIndexChoice"></CommonConfirmationModal>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue"; // Importa el componente de formulario común
import CommonConfirmationModal from "@/components/commonComponents/CommonConfirmationModal.vue"; // Importa el componente de modal de confirmación común
import { mainAxios } from "@/mainAxios.js"; // Importa la instancia de Axios configurada
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue"; // Importa el componente de autocompletado de correo electrónico

export default {
  components: {
    CommondForm, // Declara el componente de formulario común
    CommonConfirmationModal, // Declara el componente de modal de confirmación común
    CommonEmailAutocomplete, // Declara el componente de autocompletado de correo electrónico
  },
  data() {
    return {
      contactData: {
        correos: [], // Inicializa un array vacío para los correos electrónicos
      },
      route: "/v1/prospecto/perfil", // Define la ruta base para las solicitudes
      run: false, // Controla la ejecución del formulario
      dialog: false, // Controla la visibilidad del modal de confirmación
      idxToDelete: null, // Almacena el índice del correo a eliminar
      requiredRule: [(v) => !!v || "El campo es requerido"], // Define la regla de validación para campos requeridos
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol, // Obtiene el rol del usuario desde el localStorage
      dataCorreos: [], // Inicializa un array vacío para los datos de correos electrónicos
      data: {}, // Inicializa un objeto vacío para los datos del formulario
      ids: [], // Inicializa un array vacío para almacenar IDs de correos a eliminar
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken, // Configura el token de autorización para las solicitudes
        },
      },
    };
  },
  props: {
    telefonos: Object, // Define la propiedad telefonos como un objeto
    correosDataFormat: Object, // Define la propiedad correosDataFormat como un objeto
    routesContactData: Object, // Define la propiedad routesContactData como un objeto
  },
  methods: {
    // Método para obtener el ID según la pantalla en la que se encuentre
    resolvedId() {
      const currentPath = this.$route.path; // Obtiene la ruta actual
      if (currentPath.includes('/ver-micuenta') && currentPath.split('/').length <= 3) {
        return localStorage.getItem('id'); // Retorna el ID del localStorage si está en la pantalla "mi cuenta"
      }
      const pathSegments = currentPath.split('/'); // Divide la ruta en segmentos
      if (pathSegments.length > 3) {
        if (this.telefonos && this.telefonos.idAgente) {
          return this.telefonos.idAgente; // Retorna el ID del agente si está en la pantalla "mi cuenta" con un ID en la URL
        }
      }
      return null; // Retorna null si no se cumple ninguna condición
    },
    // Método para cambiar el componente
    changeComponent() {
      this.$emit("changeComponent", 1); // Emite un evento para cambiar el componente
    },
    // Método para cambiar el correo favorito
    changeFavoriteEmail(idx) {
      if (
        this.dataCorreos.correos &&
        this.dataCorreos.correos.length > idx &&
        this.dataCorreos.correos[idx].correo !== ""
      ) {
        const idxActualFavorite = this.dataCorreos.correos.findIndex(
          (correo) => correo.favorito === true
        );
        if (idxActualFavorite !== -1 && idxActualFavorite !== idx) {
          this.dataCorreos.correos[idxActualFavorite].favorito = false; // Desmarca el correo actual como favorito si existe uno
        }
        this.dataCorreos.correos[idx].favorito = true; // Marca el nuevo correo como favorito
      } else {
        this.$toast.info("¡Primero completa el campo correo!", { // Muestra un mensaje de error si el correo está vacío
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        }); // Muestra un mensaje de error si el correo no es válido
      }
    },
    // Método para verificar el índice a eliminar
    chekDeleteIdx(idx) {
      this.idxToDelete = idx; // Almacena el índice a eliminar
      if (this.dataCorreos.correos[idx].correo != "") this.dialog = true; // Muestra el modal de confirmación si el correo no está vacío
      else this.deleteIndexChoice(true); // Elimina el índice directamente si el correo está vacío
    },
    // Método para manejar la elección del usuario en el modal de confirmación
    deleteIndexChoice(val) {
      if (val) {
        var idxActualFavorite = this.dataCorreos.correos.findIndex(
          (i) => i.favorito == true
        );
        if (typeof this.dataCorreos.correos[this.idxToDelete].id != "undefined")
          this.ids.push(this.dataCorreos.correos[this.idxToDelete].id); // Agrega el ID del correo a eliminar al array de IDs
        this.dataCorreos.correos.splice(this.idxToDelete, 1); // Elimina el correo del array
        if (idxActualFavorite == this.idxToDelete) {
          this.dataCorreos.correos[0].favorito = true; // Marca el primer correo como favorito si el correo eliminado era el favorito
        }
      }
      this.idxToDelete = null; // Resetea el índice a eliminar
      this.dialog = false; // Cierra el modal de confirmación
      this.deleteEmails(); // Llama al método para eliminar correos
    },
    // Método para agregar un nuevo correo
    AddNewMail() {
      this.dataCorreos.correos.push({
        correo: "",
        favorito: false,
      }); // Agrega un nuevo objeto de correo al array
    },
    // Método para eliminar correos vacíos
    eliminatedEmptyEmails() {
      this.dataCorreos.correos = this.dataCorreos.correos.filter(
        (correo) => correo.correo.trim() !== ""
      ); // Filtra los correos vacíos del array
      this.run = true; // Ejecuta el envío del formulario
    },
    // Método para manejar el cambio de correos
    ChangeEmails() {
      if (this.data.idProspecto || localStorage.getItem("id")) {
        if (this.ids.length > 0) {
          this.deleteEmails(); // Llama al método para eliminar correos si hay IDs en el array
        }
        this.addEmail(); // Llama al método para agregar correos
        this.editEmail(); // Llama al método para editar correos
      }
      if (this.telefonos.idAgente) {
        this.actualizarCorreos(); // Llama al método para actualizar correos si hay un ID de agente
      }
      this.$emit("changeComponent", 1); // Emite un evento para cambiar el componente
      this.$emit("update-contact-data", {
        telefono: this.data.telefono,
        celular: this.data.celular,
      }); // Emite un evento con los datos actualizados
    },
    // Método para eliminar correos
    deleteEmails() {
      var body = {
        id: this.ids,
        idProspecto: this.data.idProspecto,
      };
      mainAxios
        .post(this.routesContactData.deleteEmails, body, this.config); // Realiza una solicitud POST para eliminar correos
    },
    // Método para agregar correos
    addEmail() {
      const id = this.resolvedId(); // Obtiene el ID resuelto
      const newEmails = this.dataCorreos.correos.filter((email) => email.id == null); // Filtra los correos nuevos
      if (newEmails.length > 0) { // Si hay correos nuevos
        newEmails.forEach((email) => { // Itera sobre los correos nuevos
          const body = {
            ...(this.data.idProspecto && { idProspecto: this.data.idProspecto }),
            ...(id && { agente_id: id }),
            ...email,
          };
          mainAxios
            .post(this.routesContactData.insertEmails, body, this.config) // Realiza una solicitud POST para insertar correos
            .catch((error) => {
              // Muestra un mensaje de error si la solicitud falla
              this.$toast.info(error.response.data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false, // Muestra un mensaje de error si la solicitud falla
              }); // Muestra un mensaje de error si la solicitud falla
            });
        });
      }
    },
    // Método para editar correos
    editEmail() {
      var updatedEmails = this.dataCorreos.correos.filter(
        (email) => typeof email.id != "undefined"
      ); // Filtra los correos actualizados
      if (updatedEmails.length > 0)
        updatedEmails.map((email) => {
          var body = { // Crea un objeto con los datos del correo
            agente_id: this.telefonos.idAgente, // Asigna el ID del agente
            ...email, // Asigna los datos del correo
          };
          mainAxios.post(
            this.routesContactData.changeEmails + email.id, // Realiza una solicitud POST para editar correos
            body,
            this.config
          );
        });
    },
    // Método para actualizar correos
    actualizarCorreos() {
      for (let correo of this.dataCorreos.correos) {
        if (correo.id) {
          mainAxios.post(
            `${this.routesContactData.changeEmails}${correo.id}`,
            {
              correo: correo.correo,
              favorito: correo.favorito,
            },
            this.config
          ); // Realiza una solicitud POST para actualizar correos existentes
        } else {
          mainAxios.post(
            this.routesContactData.insertEmails,
            {
              correo: correo.correo,
              favorito: correo.favorito,
              ...(this.telefonos.idAgente && { agente_id: this.telefonos.idAgente }),
              ...(this.telefonos.idCliente && { cliente_id: this.telefonos.idCliente }),
            },
            this.config
          ); // Realiza una solicitud POST para insertar nuevos correos
        }
      }
      if (this.ids.length > 0) {
        mainAxios.post(
          this.routesContactData.deleteEmails,
          { id: this.ids },
          this.config
        ); // Realiza una solicitud POST para eliminar correos si hay IDs en el array
      }
    },
  },
  mounted() {
    this.data = {
      telefono: this.telefonos.telefono,
      celular: this.telefonos.celular,
      idProspecto: this.telefonos.idProspecto,
    }; // Inicializa los datos del formulario con los datos de telefonos
    this.dataCorreos = Object.assign(this.correosDataFormat); // Asigna los datos de correos formateados
  },
};
</script>

<style scoped>
.text-header-data {
  color: #0070c0;
}

.whitout-padding-bottom {
  padding-bottom: 0px !important;
}

.whitout-padding-top {
  padding-top: 0px !important;
}

@media (max-width: 959px) {
  .mobile-container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .mobile-actions {
    margin-top: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .mobile-action-button {
    background-color: #00a7e4;
    color: white;
    font-weight: bold;
    width: 100%;
    max-width: 300px;
  }

  .mobile-action-button-cancel {
    background-color: #f9f9f9;
    color: #00a7e4;
    font-weight: bold;
    width: 100%;
    max-width: 300px;
  }
}
</style>
