<template>
  <div>
    <CommonExpansionPanel @change="togglePanel">
      <template v-slot:title-panel>
        <div class="d-flex justify-space-between align-center">
          <span>Datos de contacto</span>
          <div v-if="canEdit && isPanelExpanded && !isMobile" class="d-flex align-center button-container">
            <!-- Botón Cancelar (visible solo en edición) -->
            <v-btn
              v-if="switch_data === 2"
              rounded
              outlined
              color="#00a7e4"
              class="cancel-button mr-4"
              @click.stop="cancelEdit"
              title="Cancelar edición"
            >
              <span style="padding-right: 8px;">Cancelar</span>
            </v-btn>

            <!-- Botón Guardar/Editar -->
            <v-btn
              rounded
              dark
              class="common-button"
              @click.stop="handleEditSave"
              :title="switch_data === 1 ? 'Editar datos' : 'Guardar cambios'"
            >
              <span style="padding-right: 8px;">
                {{ switch_data === 1 ? 'Editar' : 'Guardar' }}
              </span>
              <v-icon right>
                {{ switch_data === 1 ? 'mdi-pencil' : 'mdi-content-save' }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </template>

      <v-expansion-panel-content>
        <view-data
          v-if="switch_data === 1"
          :contactData="contactData"
          :correosDataFormat="correosDataFormat"
          :canEdit="canEdit"
          @changeComponent="changeComponent"
        ></view-data>

        <edit-data
          v-else
          :telefonos="contactData"
          :correosDataFormat="correosDataFormat"
          :routesContactData="routesContactData"
          @changeComponent="changeComponent"
          @update-contact-data="updateContactData"
        ></edit-data>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewData from "./ViewData.vue";
import EditData from "./EditData.vue";

export default {
  name: "ContactDataPanel",
  components: {
    CommonExpansionPanel,
    ViewData,
    EditData,
  },
  data() {
    return {
      switch_data: 1,
      isPanelExpanded: false,
      isMobile: window.innerWidth < 768, // Detecta si la pantalla es móvil
    };
  },
  props: {
    contactData: { type: Object, required: true },
    correosDataFormat: { type: Object, required: true },
    routesContactData: { type: Object, required: true },
    canEdit: { type: Boolean, default: true },
  },
  methods: {
    handleEditSave() {
      if (this.switch_data === 1) {
        this.switchToEdit(); // Cambia a modo edición
      } else {
        this.$emit("save-data"); // Emite evento para guardar
        this.changeComponent(1); // Vuelve a vista
      }
    },
    cancelEdit() {
      this.changeComponent(1); // Cancela edición
    },
    changeComponent(component) {
      this.switch_data = component;
      this.$emit("getData");
    },
    updateContactData(contactData) {
      this.$emit("update-contact-data", contactData);
    },
    switchToEdit() {
      if (this.canEdit) this.changeComponent(2);
    },
    togglePanel(expanded) {
      this.isPanelExpanded = expanded;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768;
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped>
/* Estilos para los botones */
.common-button {
  background-color: #00a7e4 !important;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 12px;
  transition: opacity 0.3s ease-in-out;
}

.common-button:hover {
  background-color: #007bb5 !important;
}

.cancel-button {
  background-color: white !important;
  color: #00a7e4 !important;
  border: 1px solid #00a7e4 !important;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
}

.cancel-button:hover {
  background-color: #f0f0f0 !important;
}

/* Contenedor de botones con espacio responsivo */
.button-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Agrega espacio entre los botones */
}

/* Clases de diseño para alinear contenido */
.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
</style>
