<template>
    <div>
        <!-- Versión escritorio -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
        <!-- Fila que alinea los elementos verticalmente al centro -->
        <v-row class="d-flex align-center">
            <!-- Columna que se muestra si hay un número de teléfono en contactData -->
            <v-col v-if="contactData.telefono">
                <!-- Texto que indica que el dato es un teléfono -->
                <span class="text-header-data">Teléfono</span>
            </v-col>
            <!-- Columna que se muestra si hay un número de celular en contactData -->
            <v-col v-if="contactData.celular">
                <!-- Texto que indica que el dato es un celular -->
                <span class="text-header-data">Celular</span>
            </v-col>
            <!-- Espaciador para empujar los elementos hacia los extremos -->
            <v-spacer></v-spacer>
            <!-- Columna con alineación de texto a la derecha -->
            <v-col cols="auto" class="text-right">
                <!-- Botón de edición que se muestra si canEdit es verdadero -->
            </v-col>
        </v-row>
        <!-- Fila para mostrar los datos de contacto -->
        <v-row>
            <!-- Columna que muestra el número de teléfono si existe en contactData -->
            <v-col class="whitout-padding-top" cols="4" sm="4" md="4" lg="4" xl="4" v-if="contactData.telefono">
                <!-- Mostrar el número de teléfono -->
                <span>{{ contactData.telefono }}</span>
            </v-col>
            <!-- Columna que muestra el número de celular si existe en contactData -->
            <v-col class="whitout-padding-top" cols="4" sm="4" md="4" lg="4" xl="4" v-if="contactData.celular">
                <!-- Mostrar el número de celular -->
                <span>{{ contactData.celular }}</span>
            </v-col>
        </v-row>
        <!-- Fila que se muestra si hay correos electrónicos favoritos -->
        <v-row v-if="favoriteEmails.length > 0">
            <!-- Columna que ocupa todo el ancho -->
            <v-col cols="12">
                <!-- Fila interna para los correos electrónicos -->
                <v-row>
                    <!-- Columna que ocupa todo el ancho y elimina el padding inferior -->
                    <v-col cols="12" class="whitout-padding-bottom">
                        <!-- Texto que indica que los datos son correos electrónicos -->
                        <span class="text-header-data">Correos electrónicos</span>
                    </v-col>
                    <!-- Columna que itera sobre los correos favoritos y los muestra -->
                    <v-col cols="12" v-for="(correo, index) in favoriteEmails" :key="index" class="whitout-padding-top">
                        <!-- Contenedor para alinear el texto y el icono -->
                        <div style="display: flex; align-items: center;">
                            <!-- Mostrar el correo electrónico -->
                            <span>{{ correo.correo }}</span>
                            <!-- Icono de corazón si el correo es favorito -->
                            <v-icon v-if="correo.favorito" :color="'#00a7e4'" style="margin-left: 10px;">
                                mdi-heart
                            </v-icon>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </template>
    <template v-else>
      <div class="mobile-container">
        <div class="mobile-data">
          <v-row class="mobile-row">
            <v-col cols="12" v-if="contactData.telefono">
              <span class="mobile-header text-body-1">Teléfono</span>
              <br />
              <span>{{ contactData.telefono }}</span>
            </v-col>
            <v-col cols="12" v-if="contactData.celular">
              <span class="mobile-header text-body-1">Celular</span>
              <br />
              <span>{{ contactData.celular }}</span>
            </v-col>
          </v-row>
        </div>
        <div v-if="favoriteEmails.length > 0" class="mobile-emails">
          <span class="mobile-header text-body-1">Correos electrónicos</span>
          <v-row>
            <v-col cols="12" v-for="(correo, index) in favoriteEmails" :key="index" class="mobile-email-item text-body-1">
              <div style="display: flex; align-items: center; justify-content: center;">
                <span>{{ correo.correo }}</span>
                <v-icon v-if="correo.favorito" :color="'#00a7e4'" style="margin-left: 10px;">mdi-heart</v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="mobile-actions">
          <v-btn v-if="canEdit" rounded dark class="mobile-edit-button" @click="changeComponent">
            <span>Editar</span>
            <v-icon right>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // Inicializa la variable rol como una cadena vacía
            rol: "",
        };
    },
    props: {
        // Propiedad que recibe un objeto con los datos de contacto
        contactData: Object,
        // Propiedad que recibe un objeto con los datos de correos formateados
        correosDataFormat: Object,
        // Propiedad booleana que indica si se puede editar, por defecto es true
        canEdit: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        favoriteEmails() {
            // Filtra y retorna los correos que son favoritos
            return this.correosDataFormat.correos.filter(correo => correo.favorito);
        },
    },
    methods: {
        changeComponent() {
            // Emite un evento para cambiar el componente, pasando el valor 2
            this.$emit("changeComponent", 2);
        },
    },
};
</script>

<style scoped>
/* Clase que elimina el padding inferior */
.whitout-padding-bottom {
    padding-bottom: 0px !important;
}

/* Clase que elimina el padding superior */
.whitout-padding-top {
    padding-top: 0px !important;
}

/* Clase para el color de fondo favorito */
.favorite-color {
    background-color: aliceblue;
}

@media (max-width: 959px) {
  .mobile-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }

  .mobile-row {
    text-align: center;
    margin-bottom: 16px;
  }

  .mobile-header {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }

  .mobile-emails {
    text-align: center;
  }

  .mobile-email-item {
    text-align: center;
    margin-top: 8px;
  }

  .mobile-actions {
    text-align: center;
    margin-top: 16px;
  }

  .mobile-edit-button {
    background-color: #00a7e4 !important;
    color: white;
    font-weight: bold;
    width: 100%;
    max-width: 300px;
  }
}
</style>
